import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSCAPartyMasterKeys from 'src/constants/locale/key/NBCIUSCAPartyMaster.js'

function makeValidationSchema(translate) {
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_USCA_PARTY_MASTER,
    NBCIUSCAPartyMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  return Yup.object({
    partyIdentifierCode: Yup.string(),
    partyIdentifierNumber: Yup.string().matches(
      ValidationRegex.alphaNumericSpecial,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
