import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSCAPartyMasterKeys from 'src/constants/locale/key/NBCIUSCAPartyMaster.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_USCA_PARTY_MASTER,
    NBCIUSCAPartyMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )

  let errMsgAlphaNumericHyphenUnderscore = translate(
    Namespace.NBCI_USCA_PARTY_MASTER,
    NBCIUSCAPartyMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_HYPHEN_UNDERSCORE
  )

  let errMsgAlphaNumericFax = translate(
    Namespace.NBCI_USCA_PARTY_MASTER,
    NBCIUSCAPartyMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_FAX
  )

  return Yup.object({
    inputPartyID: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1')
      .max(35, 'Max length allowed is 35')
      .required(requiredMessage)
      .matches(
        '^[a-zA-Z0-9\\-\\_\\ ]+$',
        errMsgAlphaNumericHyphenUnderscore
      )
      .nullable(),
    partyName: Yup.string()
      .min(1, 'Min length needed is 1')
      .max(60, 'Max length allowed is 60')
      .required(requiredMessage)
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    nameDBA: Yup.string()
      .max(50, 'Max length allowed is 50')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    partyType: Yup.string().nullable().required(requiredMessage),
    address1: Yup.string()
      .max(35, 'Max length allowed is 35')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable()
      .required(requiredMessage),
    address2: Yup.string()
      .max(35, 'Max length allowed is 35')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    address3: Yup.string()
      .max(35, 'Max length allowed is 35')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    cityName: Yup.string()
      .max(30, 'Max length allowed is 30')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    postalCode: Yup.string()
      .max(15, 'Max length allowed is 15')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    contactPerson: Yup.string()
      .max(60, 'Max length allowed is 60')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    telNo: Yup.string()
      .max(35, 'Max length allowed is 35')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
      .nullable(),
    faxNo: Yup.string()
      .matches(
        '^[0-9\\-\\+\\(\\)\\ ]+$',
        errMsgAlphaNumericFax
      )
      .max(35, 'Max length allowed is 35')
      .nullable(),
    email: Yup.string()
      .matches('[A-Za-z0-9+_.-]+@(.+)$', 'Please enter Email in valid pattern')
      .max(50, 'Max length allowed is 50')
      .nullable(),
    partyIdentifierSection: Yup.array().nullable(),
    scac: Yup.string()
      .max(4, 'Max length allowed is 04')
      .matches(
        ValidationRegex.alphaNumericSpecial,
        errMsgAlphaNumeric
      )
  })
}

export default makeValidationSchema
